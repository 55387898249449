<template>
  <div class="productCenterBox ">
    <div class="top_left">
      <!-- <img src="../assets/img/about.jpg" alt="" /> -->
    </div>
    <div class="bottombox" >
      <!-- <div class="titlebox">
          <div class="spanbox"><span class="span1">首页</span ><span class='span2'>></span><span class="span3">产品中心</span></div>
      </div> -->
      <div class="titlebox1">
          <el-divider content-position="center">产品中心</el-divider>
      </div>
      <div class="productlistbox">
        <div class="imgListbox">
          <div class="list-one-box box1" @click="godetail(1)">
            <img class="imgbox"   src="../assets/img/chanpin4.png" >
            <div class="div-mask-one" >
              <div class="mask-one-p">
                <p class="lh22aaa">禧闻云-智联通效智能营销系统</p>
              </div>
            </div>
            <p class="p1">禧闻云智联通效</p>
          </div>
          
          <div class="list-one-box box2" @click="godetail(2)">
            <img class=" imgbox"  src="../assets/img/chanpin5.png" >
            <div class="div-mask-one" >
              <div class="mask-one-p">
                <p class="lh22aaa">数字化闭环式智慧楼宇系统</p>
              </div>
            </div>
            <p class="p1">禧闻云智联通效</p>
          </div>
          <div class="list-one-box box1" @click="godetail(3)">
            <img class="imgbox"  src="../assets/img/chanpin6.png" >
            <div class="div-mask-one" >
              <div class="mask-one-p">
                <p class="lh22aaa">产业管理服务创新平台</p>
              </div>
            </div>
            <p class="p1">产业管理服务创新平台</p>
          </div>
          <div class="list-one-box box2" @click="godetail(4)">
            <img class="imgbox"   src="../assets/img/chanpin7.png" >
            <div class="div-mask-one" >
              <div class="mask-one-p">
                <p class="lh22aaa">禧闻-智能化物联网产品</p>
              </div>
            </div>
            <p class="p1">智能化物联网产品</p>
          </div>
          <div class="list-one-box box1" @click="godetail(5)">
            <img class=" imgbox"  src="../assets/img/chanpin8.png" >
            <div class="div-mask-one" >
              <div class="mask-one-p">
                <p class="lh22aaa">禧闻-企业服务智慧平台</p>
              </div>
            </div>
            <p class="p1">企业服务智慧平台</p>
          </div>
          <div class="list-one-box box3">
             <!-- <img class=" imgbox"  src="../assets/img/chanpin8.png" >
            <div class="div-mask-one" >
              <div class="mask-one-p">
                <p class="lh22aaa">禧闻-企业服务智慧平台</p>
              </div>
            </div> -->
          </div>
        </div>
        <!--<div class="imgListbox">
          <div class="list-one-box box2" @click="godetail(4)">
            <img class="imgbox"   src="../assets/img/chanpin7.png" >
            <div class="div-mask-one" >
              <div class="mask-one-p">
                <p class="lh22aaa">禧闻-智能化物联网产品</p>
              </div>
            </div>
          </div>
          <div class="list-one-box box1" @click="godetail(5)">
            <img class=" imgbox"  src="../assets/img/chanpin8.png" >
            <div class="div-mask-one" >
              <div class="mask-one-p">
                <p class="lh22aaa">禧闻-企业服务智慧平台</p>
              </div>
            </div>
          </div>
           <div class="list-one-box">
            <img class="imgbox"  src="../assets/img/chanpin6.png" >
            <div class="div-mask-one" >
              <div class="mask-one-p">
                <p class="lh22aaa">产业管理服务创新平台</p>
              </div>
            </div>
          </div> 
        </div>-->
      </div>
      <!-- <div class="viewmoreBox">
        查看更多
      </div> -->
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from "../components/footers.vue";
export default {
  components: {
    footers
  },
  methods:{
    godetail(val){
        this.$router.push(`/productDetail?keyword=${val}`)
      }
  }
}
</script>

<style lang="less" scoped>
  .top_left {
    width: 100%;
    height: 300px;
    background: url("../assets/img/chanpingzhongxin.jpg") no-repeat left center;
    background-size: 100% 100%;
  }
  .titlebox1{
    width: 1200px;
    margin-top: 44px;
    font-size: 24px;
  }
  .productlistbox{
    width: 1258px;
    margin-bottom: 60px;
    
  }
  .bottombox{
    display: flex;
    align-items: center;
    flex-direction: column;
      .titlebox{
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        height: 60px;
        width: 1000px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span{
          font-size: 12px;
        }
        .span1{
          color: #073763;
        }
        .span2{
          margin: 0 10px;
        }
        .span3{
          color: #000;
        }
      }
      .viewmoreBox{
        width: 180px;
        height: 38px;
        font-size: 16px;
        border: 2px solid #2263b9;
        color: #2263b9;
        line-height: 38px;
        text-align: center;
        border-radius: 10px;
        margin-top: 80px;
        margin-bottom: 80px;
        background: #ffffff;
        cursor: pointer;
      }
      .viewmoreBox:hover{
        color: #fff;
        background: #2263b9;
        transition: all 300ms;
      }
  }
  

.imgListbox{
  display: flex;
  align-content: center;
  flex-flow:row wrap;
  justify-content: space-between;
  .box3{
      display: block;
    }
}
.list-one-box {
    margin-top: 44px;
    margin: 44px 0 22px;
    position: relative;
    display: inline-block;
    width: 390px;
    height: 300px;
    cursor: pointer;
    overflow: hidden;
    img{
      cursor: pointer;
      position: relative;
      object-fit:cover;
      width: 390px;
      height: 300px;
      transition: all 0.4s;
    }
    .p1{
      display: none;
    }
  }
  .div-mask-one {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    cursor: pointer;
    height: 0px;
    // background-color: transparent;
    background-color: rgba(7, 55, 99, 0.82);
    /* 垂直居中展示 注意点 如果使用绝对居中，其内容内容要用一个div包起来 */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    transition: height 400ms;
    font-weight: 700;
  }
  
  .list-one-box:hover .div-mask-one {
    height: 100%;
    transition: height 400ms;
    // transition: all 0.4s;
  }
  .list-one-box:hover .imgbox {
    transform: scale(1.2);
    transition: all 0.4s;
  }
  .mask-one-p {
    display: none;
  }
  .lh22aaa{
    line-height: 22px;
  }
  .list-one-box:hover .mask-one-p {
    display: block;
  }

  @media (max-width: 750px) {
    .top_left {
      width: 100%;
      height: 150px;
      background: url("../assets/img/chanpingzhongxin.png") no-repeat left center;
      background-size: 100% 100%;
    }
    .titlebox1{
      margin-top: 20px;
      font-size: 18px;
      .el-divider--horizontal{
        margin: 12px 0;
      }
    }
    .productlistbox{
      width: 100%;
      margin-bottom: 20px;
      .imgListbox{
        flex-flow:row wrap;
        .box3{
          display: none;
        }
      }
    }
    .list-one-box{
      width: 160px;
      height: 143px;
      margin: 22px 0 0 0 ;
      img{
        width: 160px;
        height: 123px;
      }
      .p1{
        display: block;
        position: absolute;
        bottom: -12px;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .lh22aaa{
      line-height: 18px;
    }
    .box1{
      margin-left: 6px;
      margin-right: 6px;
    }
    .box2{
      margin-right: 6px;
    }
  }
</style>

<style lang="less">
.productCenterBox{
  .el-divider__text{
    color: #3D3D3D;
    font-size: inherit;
    font-weight: bold;
  }
}

</style>